import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { styles, Heading, Padding, PinkDelimiter, Text } from "../common/styles"
import { media } from "../common/mediaQueries"
import styled from "@emotion/styled"
import parse from "html-react-parser"
import { StaticQuery, graphql } from "gatsby"

const Datenschutz = () => {
  return (
    <Layout>
      <StaticQuery
        query={graphql`
          {
            allWordpressPage(
              filter: { title: { eq: "Datenschutzerklärung" } }
            ) {
              edges {
                node {
                  id
                  title
                  content
                }
              }
            }
          }
        `}
        render={data => {
          const dsgvo = data.allWordpressPage.edges[0].node
          return (
            <>
              <SEO title={dsgvo.title} keywords={["Datenschutzerklärung"]} />
              <Wrapper>
                <Padding value="2vw" axis="y" />
                <HeadingWrapper>
                  <Heading color={styles.colors.blue.dark}>
                    {dsgvo.title}
                  </Heading>
                  <Padding value="1vw" axis="y" />
                  <PinkDelimiter />
                </HeadingWrapper>
                <Padding value="2vw" axis="y" />
                <Text>{parse(dsgvo.content)}</Text>
              </Wrapper>
            </>
          )
        }}
      />
    </Layout>
  )
}

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  width: 80vw;
  height: 100%;
  margin: 0 auto;
  .wp-block-embed-youtube {
    width: 80vw;
    * {
      width: 60vw;
      height: 33.75vw;
      margin: 0 auto;
    }
  }
  ${media.mobile} {
    .wp-block-embed-youtube {
      width: 80vw;
      * {
        width: 80vw;
        height: 45vw;
        margin: 0 auto;
      }
    }
  }
`

export default Datenschutz
